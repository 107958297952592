<template>
    <b-card>
        <div class="d-flex justify-content-between">
            <h5>Recommended for You</h5>
            <router-link :to="{name: 'learn-and-develop-grid', params: {recommended: true}}" v-if="recommendedToolkits.length > 3">View all</router-link>
        </div>
        <what-does-this-mean type="home-recommended-toolkits" />

        <b-row>
            <b-col cols="12" lg="4" md="6" class="mb-md-0 pb-2 my-2">
                <b-form-select
                    v-model="category"
                    :options="sortedCategories"
                    @change="getRecommendedToolkitList(category)"
                />
            </b-col>
        </b-row>
        
        <b-overlay :show="show" rounded="sm" class="mt-md-2">
            <div style="min-height: 300px;" v-if="recommendedToolkits.length > 0">
                <div dir="">
                    <slick v-if="readyForSlick" ref="slick" :options="slickOptions">

                    <new-toolkit-card
                        v-for="rToolkit in recommendedToolkits"
                        :key="rToolkit.id"
                        :toolkit="rToolkit"
                        :showCategory="true"
                    />
                    </slick>
                </div>
            </div>
            <div v-else class="d-flex justify-content-center align-items-center" style="min-height: 300px;">
                <p>There are no recommended toolkits for the selected mental health check</p>
            </div>
        </b-overlay>

        <div class="text-center" v-if="showButton">
            <b-button variant="primary" class="mt-2" @click="goToLearnAndDevelop">View all</b-button>
        </div>

        <update-toolkit-reminder v-if="this.$route.name == 'my-hub'" />
    </b-card>
</template>

<script>
import store from "@/store/index";
import NewToolkitCard from "@/components/toolkit/NewToolkitCard.vue";
import WhatDoesThisMean from "@/components/modals/WhatDoesThisMean.vue";
import UpdateToolkitReminder from "@/components/modals/UpdateToolkitReminder.vue";

import {
    BOverlay,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormSelect
} from "bootstrap-vue";

import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        NewToolkitCard,
        WhatDoesThisMean,
        UpdateToolkitReminder,
        ToastificationContent,
        BOverlay,
        BCard,
        BButton,
        BRow,
        BCol,
        BFormSelect,
        Slick
    },
    props: ['showButton', 'questionnaire'],
    data() {
        return {
            show: true,
            recommendedToolkits: [],

            category: "all",
            categoryOptions: [],

            // Slick carousel
            slickOptions: {
                autoplay: true,
                autoplaySpeed: 2000,
                speed: 2000,
                arrows: false,
                slidesToShow: 3,
                swipeToSlide: true,
                infinite: false,
                slidesToScroll: 1,
                responsive: [
                {
                    breakpoint: 1666,
                    settings: {
                    slidesToShow: 2.6,
                    centerMode: true,
                    },
                },
                {
                    breakpoint: 1555,
                    settings: {
                    slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 900,
                    settings: {
                    slidesToShow: 1.6,
                    slidesToScroll: 0.5,
                    },
                },
                {
                    breakpoint: 784,
                    settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    },
                },
                ],
            },
            readyForSlick: false,
        }
    },
    computed: {
        sortedCategories() {
            let sortedCategoryOptions = this.categoryOptions.concat().sort((a,b) => a.text.localeCompare(b.text))
            let firstElement = { value: "all", text: "All" }
            let newArray = [firstElement].concat(sortedCategoryOptions)

            return newArray
        }
    },
    methods: {
        getRecommendedToolkitList(questionnaireId) {
            // Deactivate slick carousel and show overlay
            this.readyForSlick = false
            this.show = true

            if(questionnaireId == null) {
                questionnaireId = 'all'
            }

            if(Number.isInteger(this.questionnaire)) {
                questionnaireId = this.questionnaire
            }

            this.$http.get("/api/auth/toolkits?recommended=true&questionnaire=" + questionnaireId)
            .then((res) => {
                if(res != undefined) {
                    this.recommendedToolkits = res.data.toolkits
                    
                    // Activate slick carousel and hide overlay
                    this.readyForSlick = true
                    this.show = false
                }
            })
            .catch((err) => {
                console.log(err);
            })
        },
        goToLearnAndDevelop() {
            this.$router.push("/learn-and-develop");
        },
        getEnrolledQuestionnaires() {
            this.$http.get("/api/auth/toolkits?recommended=true&questionnaire=all")
            .then((res) => {
                if(res != undefined) {
                    // Questionnaires for select box
                    res.data.questionnaires.forEach((element) => {
                        this.categoryOptions.push({
                            value: element.id,
                            text: element.title + ' (' + element.subcategory.questionnairecategory.name + ')',
                        });
                    })
                        
                }
            })
            .catch((err) => {
                console.log(err);
                // Hide overlay
                this.show = false;

                // Show toast
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong. Please try again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            });
        },
    },
    mounted() {
        this.getRecommendedToolkitList('all')
        this.getEnrolledQuestionnaires()
    },
    watch: {
        recommendedToolkits(val) {
            this.readyForSlick = true;
        },
    }
}
</script>

<style lang="scss">
    // slick
    // .recommended-toolkit {
    //     height: 100%;
    //     background-color: #f8f8f8;
    //     display: flex !important;
    // }

    .slick-track {
        display: flex !important;
        margin-left: 0;
        margin-right: 0;
    }

    .slick-slide {
        height: inherit !important;
        padding-right: 2rem;
    }

    .slick-slide > div {
        height: 100%;
    }

    @media (min-width: 1921px) {
        .toolkit-grid-container {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    @media (max-width: 784px) {
        .slick-slide {
            padding-right: 0;
            margin: 0 27px;
        }

        /* the parent */
        .slick-list {
            margin: 0 -27px;
        }
    
    @media (max-width: 1441px) {
        .toolkit-grid-container {
            grid-template-columns: 1fr;
            }
        }
    }
    // slick end

</style>
