<template>
    <div class="toolkit-card">
        <div v-if="showCategory">
            <div class="toolkit-category" v-if="toolkit.category.color != null" :style="{'background-color': toolkit.category.color}">
                <h5 class="text-white mb-0">
                  {{ toolkit.category.name }}
                </h5>
            </div>
            <div class="toolkit-category" v-else :style="{'background-color': '#FFF'}">
                <h5 class="mb-0">
                  {{ toolkit.category.name }}
                </h5>
            </div>
        </div>
        <div class="toolkit-card-body" v-bind:class=" showCategory ? 'b-tl-r-0' : '' ">
            <div class="limited-title w-100 text-center mb-1">
                {{ toolkit.title }}
            </div>

            <div class="d-flex justify-content-between">
              <div class="row px-3 px-md-0 toolkit-details-container">
                <div class="col-12 col-md-4">
                  <!-- image col -->
                  <div>
                    <b-img
                      v-if="!toolkit.image_url"
                      fluid
                      :src="require('@/assets/images/logo-big.png')"
                    />
                    <b-img v-else fluid :src="toolkit.image_url" />
                  </div>

                </div>

                <div class="col-12 col-md-8">
                  <!-- text col -->
                  <div class="d-flex flex-column toolkit-text-container">
                    <div class="mr-1 limited-description">
                      {{ toolkit.description }}
                    </div>
                  </div>
                </div>

              </div>

              <div style="position: absolute; right: 20px;">
                  <div v-if="toolkit.ever_completed == 1">
                      <div class="checkmark-container">
                          <feather-icon icon="CheckIcon" />
                      </div>          
                      <div class="reminder-settings-btn">
                          <feather-icon @click="openReminderSettingsModal(toolkit)" icon="SettingsIcon" />
                      </div>
                  </div>
              </div>
            </div>

            <div class="d-flex justify-content-between mt-2 toolkit-card-bottom">
                <div class="mr-2 d-flex flex-column justify-content-end">
                  <div><small>Created by - {{ toolkit.author }}</small></div>
                  <div><small v-if="toolkit.about_author != '' && toolkit.about_author != null" class="limited-about-autor-text">About the Author - {{ toolkit.about_author }}</small></div>
                </div>
                <div class="get-started-and-continue-btn-container">
                    <div
                        v-if="toolkit.ever_started == 1 && toolkit.completed == 0"
                        class="text-right"
                        style="padding-bottom: 0.5rem"
                    >
                        <b-badge variant="light-secondary">
                        {{ percentOfFinishedWorksheets }}% completed
                        </b-badge>
                    </div>

                    <div class="text-right">
                        <b-button
                            class="bg-mhc-dark text-nowrap"
                            @click="openToolkit"
                            v-if="toolkit.ever_started == 1 && toolkit.completed == 0"
                            >Continue</b-button
                        >
                        <b-button class="bg-mhc-dark text-nowrap" @click="openToolkit" v-else
                            >Get Started</b-button
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BBadge,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormSelect
} from "bootstrap-vue";
import EventBus from '@/main.js'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BImg,
    BFormSelect
  },
  props: {
    toolkit: {
      type: Object,
      required: true,
    },
    showCategory: {
      type: Boolean,
    },
  },
  computed: {
    percentOfFinishedWorksheets() {
      let percentage = Math.round((this.toolkit.num_of_results / this.toolkit.num_of_worksheets) * 100);

      // If num_of_results == 0 and num_of_worksheets == 0
      if(isNaN(percentage)) {
        percentage = 0
      } 

      // If percentage is 100 but user didn't click "Finish" button show 99% 
      if(percentage == 100) {
        percentage = 99
      }

      return percentage;
    },
  },
  methods: {
    openToolkit() {
      // Reset worksheet index
      this.$store.dispatch("toolkitStoreModule/resetWorksheetIndex");

      // Reset toolkit progress
      this.$store.dispatch("toolkitStoreModule/setToolkitProgress", 0);

      // Open toolkit worksheets
      this.$router.push({
        name: "learn-and-develop-single",
        params: { 
          slug: this.toolkit.slug,
          usingApp: true 
        }
      });
    },
    openReminderSettingsModal(toolkit) {
      EventBus.$emit('openReminderSettingsModal', toolkit);      
    }
  },
};
</script>

<style scoped>
.toolkit-category {
    padding: 10px;
    width: fit-content;
    background-color: orange;
}

.toolkit-card-body {
    position: relative;
    min-height: 260px;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 0.428rem;
    border: 2px solid #59949c;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    margin-bottom: 20px;
    min-height: 300px;
}

.toolkit-card-body.b-tl-r-0 {
    border-top-left-radius: 0px;    
}

.toolkit-card-bottom {
    flex-grow: 1;
}

.reminder-settings-btn {  
  position: absolute;
  right: 4px;
  top: 40px;
}

.reminder-settings-btn:hover {
  cursor: pointer;
}

.limited-description, .limited-about-autor-text, .limited-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.limited-title {
  font-size: 20px;
  margin-right: 25px;
  font-weight: bold;
}

.limited-about-autor-text{
    -webkit-line-clamp: 4; /* number of lines to show */
}

.toolkit-image-container img {
  max-height: 70px;
}

@media (max-width: 784px) {
  .toolkit-details-container {
    text-align: center;
  }
}

.checkmark-container {
  background-color: #f0f0f1;
  border-radius: 100%;
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.get-started-and-continue-btn-container{
  display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

</style>