<template>
  <div>
    <b-row class="match-height">
      <b-col lg="12">
        <recommended-toolkits />
      </b-col>
    </b-row>

    <b-overlay
      id="upgrade-overlay"
      :show="upgradeOverlay"
    >
      <div>
        <b-card no-body>
          <b-card-header class="pb-50">
            <h5>Filters</h5>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="12" md="4" lg="4" xl="3" class="mb-md-0 mb-2">
                <label>Category</label>
                <b-form-select
                  @change="filterToolkits"
                  v-model="category"
                  :options="categoryOptions"
                  class="w-100"
                />
              </b-col>
              <b-col cols="12" md="5" lg="6" xl="2" class="mb-md-0 mb-2">
                <label>Search</label>
                <b-form-input
                  v-model="toolkitSearch"
                  type="text"
                  placeholder="Keyword"
                />
              </b-col>
              <b-col cols="12" md="3" lg="2" class="mb-md-0 mb-2" style="padding-top: 3px;">
                <label></label>
                <b-button class="bg-mhc-dark" block @click="filterToolkits">
                  <feather-icon
                    icon="FilterIcon"
                    class="mr-50"
                  />
                  Search 
                </b-button>
              </b-col>
              <b-col cols="12" lg="12" xl="5" class="mb-md-0 mb-2">
                <div class="letterFilter w-500 mx-auto mt-0 mt-md-2">
                  <span
                    class="text-uppercase"
                    v-for="(letter, index) in letters"
                    :key="index"
                    @click="setLetter(letter)"
                    v-bind:class="{
                      'green-text': letter.flag,
                      'text-underline': firstLetter == letter.letter,
                    }"
                    >{{ letter.letter }}</span
                  >
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </div>
      
      <div v-for="(toolkitCategory, index) in toolkits" :key="index">

        <b-card v-if="toolkitCategory.toolkits.length > 0">
          <div class="d-flex justify-content-between">
            <div class="toolkit-category" v-if="toolkitCategory.color != null" :style="{'background-color': toolkitCategory.color}">
              <h5 class="text-white mb-0">
                {{ toolkitCategory.name }}
              </h5>
            </div>
            <div v-else>
              <h5 class="mb-0">
                {{ toolkitCategory.name }}
              </h5>
            </div>
            <router-link :to="{name: 'learn-and-develop-grid', params: {category: toolkitCategory.id}}">View all</router-link>
          </div>
          
          <div class="mt-2" :key="updateKey">
            <slick :ref="'slick' + index" :options="slickOptions">
              <new-toolkit-card
                v-for="toolkit in toolkitCategory.toolkits"
                :key="toolkit.id"
                :toolkit="toolkit"
                :category="false"
              />
            </slick>
          </div>
        </b-card>
        
      </div>

      <template #overlay>
        <div class="text-center">
          <b-card class="p-1" style="border-radius: 25px;">
            <h3 class="font-weight-normal">Upgrade for full access</h3>
            <p class="mt-1 font-weight-normal">Limited trial offer from £1</p>
            <b-button @click="tellMeMore" variant="primary" class="mt-1">Tell me more</b-button>
          </b-card>
        </div>
      </template>
        
    </b-overlay>

    <update-toolkit-reminder />

  </div>
</template>

<script>
import RecommendedToolkits from "@/components/toolkit/RecommendedToolkits.vue";
import NewToolkitCard from "@/components/toolkit/NewToolkitCard.vue";
import UpdateToolkitReminder from "@/components/modals/UpdateToolkitReminder.vue"
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BFormSelect,
  BFormInput,
  BButton,
  BOverlay,
  BPagination
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import WhatDoesThisMean from "../../components/modals/WhatDoesThisMean.vue";
import EventBus from '@/main.js'

// import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Slick from "vue-slick";
import "slick-carousel/slick/slick.css";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormSelect,
    BFormInput,
    BButton,
    BOverlay,
    RecommendedToolkits,
    NewToolkitCard,
    UpdateToolkitReminder,
    // VuePerfectScrollbar,
    Slick,
    BPagination,
    WhatDoesThisMean
  },

  data() {
    return {
      currentPage: 1,
      toolkitSearch: "",
      category: "all",
      categoryOptions: [{ value: "all", text: "All" }],
      firstLetter: null,
      letters: [{ letter: "all", flag: true }],
      questionnaireCategories: "all",
      slickOptions: {
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 2000,
        arrows: false,
        slidesToShow: 3,
        swipeToSlide: true,
        infinite: true,

        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1666,
            settings: {
              slidesToShow: 2.6,
              centerMode: true,
            },
          },
          {
            breakpoint: 1555,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 1.6,
              slidesToScroll: 0.5,
            },
          },
          {
            breakpoint: 784,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      readyForSlick: false,
      updateKey: 0
    };
  },

  computed: {
    show() {
      return this.$store.getters["toolkitStoreModule/showOverlay"];
    },
    toolkits() {
      return this.$store.getters["toolkitStoreModule/toolkitList"];
    },
    recommendedToolkits() {
      return this.$store.getters["toolkitStoreModule/recommendedToolkitList"];
    },
    upgradeOverlay() {
      if(this.$store.getters["app/fullPackagePrice"] != 0) {
        return false
      } else {
        return true
      }
    },
    questionnaireCategoriesOptions() {
      return this.$store.getters["toolkitStoreModule/questionnaireCategoriesOptions"];
    }
  },

  methods: {
    viewAll(category) {
      this.$router.push({ path: 'learn-and-develop/view/all', query: { category: category } })
    },
    getAllToolkitCategories() {
      this.$http
        .get("/api/auth/toolkit-categories")
        .then((res) => {
          if(res != undefined) {
            for (let i = 0; i < res.data.length; i++) {
              // Push categires direct to categoryOptions because we need "all" value
              this.categoryOptions.push({
                value: res.data[i].id,
                text: res.data[i].name,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong. Please login again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },

    getLetters() {
      return new Promise((resolve, reject) => {
        // Get all letters
        this.$http
          .get("/api/auth/toolkits/first-letters")
          .then((res) => {
            if(res != undefined) {
              this.letters = res.data;
              this.letters.splice(0, 0, { letter: "all", flag: true });

              for (let i = 0; i < this.letters.length; i++) {
                if (this.letters[i].flag == true) {
                  this.firstLetter = this.letters[i].letter;
                  break;
                }
              }

              resolve();
            }
          })
          .catch((error) => {
            console.log(error);

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong. Please try again",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });
      });
    },

    setLetter(letter) {
      this.firstLetter = letter.letter;
      // Reset search term
      this.toolkitSearch = "";

      this.getToolkitList();
    },

    getToolkitList() {
      this.$store.dispatch('toolkitStoreModule/showOverlay', true)

      this.$store.dispatch(
        "toolkitStoreModule/getToolkitList",
        { letter: this.firstLetter, category: this.category, search: this.toolkitSearch, page: this.currentPage, recommended: false }
      );

    },

    filterToolkits() {
      this.getToolkitList();
      this.updateKey++;
    },

    tellMeMore() {
      this.$router.push("/change-subscription-plan");      
    },
  },

  mounted() {
    // Get full package price
    this.$store.dispatch('app/getFullPackagePrice')

    this.getLetters();

    this.getToolkitList();
    this.$store.dispatch("toolkitStoreModule/getRecommendedToolkitList");

    // Get all toolkit categories
    this.getAllToolkitCategories();

    EventBus.$on('resetToolkitList', () => {
      this.getLetters();
      this.getToolkitList();
      this.$store.dispatch("toolkitStoreModule/getRecommendedToolkitList");
      // Get all toolkit categories
      this.getAllToolkitCategories();
    });

    EventBus.$on("updatedToolkitList", () => {
      this.updateKey++;
    });

  },
  watch: {
    recommendedToolkits(val) {
      this.readyForSlick = true;
    },
    currentPage(val) {
      this.getToolkitList();
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#toolkit-list-overlay .position-absolute .bg-light + .position-absolute {
  position: absolute !important;
  max-width: 0px !important;
}

#upgrade-overlay .position-absolute .bg-light + .position-absolute {
  /*top: 50%;*/
  /* left: 50%; */
  /* transform: translateX(-50%) translateY(-50%); */
  position: sticky !important;
  left: 0 !important;
  right: 0 !important;
  max-width: 340px;
  margin: 0 auto;
  transform: unset !important;
}

.letterFilter {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.letterFilter span {
  color: #bbb;
}

.text-underline {
  text-decoration: underline;
}

.green-text {
  color: #59949c !important;
  font-weight: bold;
  cursor: pointer;
}

.w-500 {
  max-width: 500px;
}

.toolkit-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding-bottom: 2rem;
}

.toolkit-flex-container {
  display: flex;
  overflow-x: auto;
}

.one-item {
  grid-template-columns: repeat(auto-fit, minmax(25rem, 50%));
}

// slick
.recommended-toolkit {
  height: auto;
}

.slick-track {
  display: flex !important;
  margin-left: 0;
  margin-right: 0;
}

.slick-slide {
  height: inherit !important;
  padding-right: 2rem;
}

.slick-slide > div {
  height: 100%;
}

.toolkit-category {
    padding: 10px;
    width: fit-content;
    background-color: orange;
}

@media (min-width: 1921px) {
  .toolkit-grid-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 784px) {
  .slick-slide {
    padding-right: 0;
  }
  
  @media (max-width: 1441px) {
    .toolkit-grid-container {
      grid-template-columns: 1fr;
    }
  }
}
// slick end

@media (max-width: 430px) {
  .toolkit-grid-container,
  .one-item {
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  }

  .letterFilter {
    display: block;
    text-align: center;
  }
}
</style>
