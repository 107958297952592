<template>
    <b-modal id="updateToolkitReminder" ref="updateToolkitReminder" hide-footer centered title="Change Toolkit Cue Card Reminder Settings">
        <div class="mt-2 text-center">
            <div class="w-500 mx-auto">
            <label>Toolkit Cue reminder frequency</label>
            <b-form-select v-model="toolkitReminder" :options="reminderOptions"/>
            </div>
            <b-button class="mt-3 float-right" :disabled="saveBtnDisabled" variant="primary" @click="saveReminderSettings()">Save</b-button>
        </div>
    </b-modal>
</template>

<script>
import EventBus from '@/main.js'

import {
  BFormSelect,
  BButton,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
    components: {
        BFormSelect,
        BButton,
    },
    data() {
        return {
            saveBtnDisabled: false,
            toolkitReminder: "weekly",
            reminderOptions: [
                { value: "weekly", text: "Weekly"},
                { value: "monthly", text: "Monthly"},
                { value: "quarterly", text: "Quarterly"},
                { value: "never", text: "Never"},
            ],
            toolkitToUpdate: null,

        }
    },
    methods: {
        saveReminderSettings() {
            this.saveBtnDisabled = true;
            this.$http.patch("/api/auth/toolkit/reminder-frequency/" + this.toolkitToUpdate.enrollment.id, {
                "reminder_frequency": this.toolkitReminder
                })
                .then((res) => {
                    this.saveBtnDisabled = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: "Cue card reminder frequency updated!",
                        icon: "CheckSquareIcon",
                        variant: "success",
                        },
                    });

                    EventBus.$emit('resetToolkitList')
                    this.$refs["updateToolkitReminder"].hide();
                })
                .catch((err) => {
                    console.log(err);
                    this.saveBtnDisabled = false;

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: "Something went wrong. Please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                        },
                    });
                });
        }
    },
    mounted() {
        EventBus.$on('openReminderSettingsModal', (toolkit) => {
            this.toolkitToUpdate = toolkit;
            this.toolkitReminder = toolkit.enrollment.reminder_frequency;

            if(this.$refs["updateToolkitReminder"] != undefined) {
                this.$refs["updateToolkitReminder"].show();
            }
        });
    }
}
</script>